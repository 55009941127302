<template>
	<a-modal :title="modalTitle" :visible="visible" :centered="true" :width="700" @cancel="close" v-if="visible">
		<a-steps :current="createStep" size="small" class="mb-4">
			<a-step title="Choose Template" />
			<a-step title="Signing Order" />
		</a-steps>
		<transition name="slide-fadein-right" mode="out-in">
			<a-form-model ref="newDoc" :model="newDoc" v-if="createStep === 0" :key="1">
				<div>
					<a-form-model-item prop="isManual" :rules="{ required: true }">
						<template slot="label">
							Signing Method
							<a-tooltip overlayClassName="change-tooltip-color" placement="right">
								<template slot="title" style="width: 400px;">
									Select e-signature if at least one party needs to sign digitally.
								</template>
								<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
							</a-tooltip>
						</template>
						<a-radio-group v-model="newDoc.isManual">
							<a-radio-button :value="false">
								<a-icon type="file-protect" /> E-Signature
							</a-radio-button>
							<a-radio-button :value="true">
								<a-icon type="highlight" /> Print &amp; Sign
							</a-radio-button>
						</a-radio-group>
					</a-form-model-item>
					<a-alert v-if="!integrated.active && !newDoc.isManual" type="error" show-icon class="mb-3">
						<template slot="message">
							To send a reservation agreement, please set up the DocuSign Integration first. The DocuSign integration is not currently configured on this project. <router-link to="/settings?docusign"><strong style="color: red">Click here</strong></router-link> to setup.
							Alternatively, you can choose the <strong>Print &amp; Sign</strong> option.
						</template>
					</a-alert>
				</div>

				<div v-if="type !== 'release'">
					<a-form-model-item label="Select Document Template" prop="template" :rules="{ required: true }">
						<a-select option-label-prop="label" v-model="newDoc.template" style="width: 50%;">
							<a-select-option :value="template.id" v-for="template in reservationTemplates"
								:key="template.id" :label="template.name">
								<div class="dF aC template-list">
									<div>
										<i class="fe fe-file-text" style="font-size:15px;" />
									</div>
									<div class="f1 ml-2">
										{{ template.name }}
										<small class=block>{{ template.pages.length }} pages contract</small>
									</div>
								</div>
							</a-select-option>
						</a-select>
					</a-form-model-item>

					<a-form-model-item prop="sendReservationAmount">
						<template slot="label">
							Collect Reservation Fee
							<a-tooltip overlayClassName="change-tooltip-color">
								<template slot="title">
									Use this field if you want to collect a reservation fee from the purchaser. It will send a payment link to the primary purchaser once all the required party has signed the reservation agreement.
								</template>
								<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
							</a-tooltip>
						</template>
						<a-switch v-model="newDoc.sendReservationAmount" />
					</a-form-model-item>
					<a-row :gutter="24" v-if="newDoc.sendReservationAmount">
						<a-col :span="6">
							<a-form-model-item label="Reservation Currency"
								:rules="req('Please select reservation currency')" prop="currency">
								<a-select v-model="newDoc.currency">
									<a-select-option value="USD">
										$ USD
									</a-select-option>
									<a-select-option value="CAD">
										$ CAD
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="Reservation Amount" :rules="req('Please enter reservation amount')"
								prop="reservationAmount">
								<a-input-number v-model="newDoc.reservationAmount" placeholder="Reservation Amount"
									:formatter="price => `$ ${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
									style="width: 50%;" />
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-alert v-if="newDoc.sendReservationAmount && !instance.stripeAccountActivated" type="error" show-icon >
						<template slot="message">
							To receive payments, please set up the payment integration first. The payment integration is not currently configured on this project. <a :href="`https://inventory.bildhive.${$tld}/settings?payments-connection=true`" target="_blank"><strong style="color: red">Click here</strong></a> to setup.
						</template>
					</a-alert>
				</div>
				<div v-else>
					<a-form-model-item label="Select Document Template" prop="template" :rules="{ required: true }">
						<a-select option-label-prop="label" v-model="newDoc.template" style="width: 50%;">
							<a-select-option :value="template.id" v-for="template in contractTemplates" :key="template.id"
								:label="template.name">
								<div class="dF aC template-list">
									<div>
										<i class="fe fe-file-text" style="font-size:15px;" />
									</div>
									<div class="f1 ml-2">
										{{ template.name }}
										<small class=block>{{ template.pages.length }} pages contract</small>
									</div>
								</div>
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</div>

				<div class="dF fC aC jC" v-if="loadModal">
					<a-icon type="loading" style="font-size:30px;" />
					<p>Analyzing Document for Relevant Questions...</p>
				</div>

				<div v-else-if="newDoc.template">
					<a-card title="Custom Fields" v-if="customFields.length" class="mt-3">
						<a-form-model-item v-for="item in customFields" :key="item.value"
							:label="item.name || 'Unnamed Question'"
							:val="`newDoc.questions.customFields['${item.value}']`">
							<template v-if="item.type === 'text'">
								<a-input v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]" />
							</template>

							<template v-else-if="item.type === 'checkmark'">
								<a-switch v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]" />
							</template>
							<template v-else-if="item.type === 'multiple'">
								<a-select :value="newDoc.customFields[item.value.replace('other.customFields.', '')]"
									@change="(e) => newDoc.customFields[item.value.replace('other.customFields.', '')] = e">
									<a-select-option v-for="opt in item.configure.options" :key="opt.value"
										:value="opt.value">{{ opt.value }}</a-select-option>
								</a-select>
							</template>
							<template v-else-if="item.type === 'date'">
								<a-date-picker format="MMM DD, YYYY"
									v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]" />
							</template>
							<template v-else-if="item.type === 'inclusion'">
								<a-select mode="multiple"
									v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]">
									<a-select-option v-for="(inclusion, index) in inclusions" :key="inclusion.value + index"
										:value="inclusion.body" :label="inclusion.label">{{ inclusion.label
										}}</a-select-option>
								</a-select>
							</template>
							<template v-else>
								<ImageBoxSelector
									v-model="newDoc.customFields[item.value.replace('other.customFields.', '')]" />
							</template>
						</a-form-model-item>
					</a-card>
				</div>
			</a-form-model>

			<div class="mt-4" v-if="createStep === 1" :key="2">
				<div>
					<div class="dF fC aC" v-if="showRoute">
						<div style="position:sticky; top:10px">
							<h4 class="text-center mt-2">Sign Route Preview</h4>
							<SignRoutePreview :signRoutes="signRoutePreview" />
						</div>
					</div>
					<div style="max-width:750px;" v-else>

						<a-checkbox v-model="requiresRepReview" class="ml-4 mb-4">
							Requires Rep to Review Contract
						</a-checkbox>

						<template v-if="requiresRepReview">
							<SigningParty v-model="reviewer" :repReviewer="requiresRepReview" />
							<a-divider />
						</template>

						<template v-if="signing.purchaserSide && signing.purchaserSide.length">
							<SigningParty v-for="(signer, signerI) in signing.purchaserSide" :showError="showError"
								v-model="signing.purchaserSide[signerI]" :key="signer.id" />
							<a-divider />
						</template>
						<p v-else class="text-danger">
							You have no one from Purchaser side assigned.
						</p>


						<template v-if="signing.vendorSide && signing.vendorSide.length">
							<SigningParty v-for="(signer, signerI) in signing.vendorSide" :showError="showError"
								:choices="pChoices" v-model="signing.vendorSide[signerI]" :key="signer.id" />
							<a-divider />

						</template>

						<template v-if="signing.other && signing.other.length">
							<SigningParty v-for="(signer, signerI) in signing.other" :showError="showError"
								:choices="pChoices" v-model="signing.other[signerI]"
								@delete="signing.other.splice(signerI, 1)" :key="signer.id" />
							<a-divider />
						</template>


						<a-button icon="plus" type="primary" size="large" @click="addRecipient">Additional
							Recipients</a-button>
					</div>
				</div>
			</div>
		</transition>

		<div slot="footer" class="dF jSB">
			<template>
				<a-button @click="createStep = 0" :disabled="loadModal" size="large" v-if="createStep > 0">BACK</a-button>
				<span v-else></span>
				<div class="dF" v-if="createStep === 1">
					<a-switch v-model="showRoute" :disabled="loadModal">
						<a-icon slot="checkedChildren" type="check" />
						<a-icon slot="unCheckedChildren" type="close" />
					</a-switch>
					<span class="ml-2">
						Preview Signing Route
					</span>
				</div>
				<a-button
					:disabled="loadModal || (!integrated.active && !newDoc.isManual) || (newDoc.isManual && !newDoc.template) || (createStep === 0 && !newDoc.template) || (newDoc.sendReservationAmount && !instance.stripeAccountActivated)"
					:icon="loadModal ? 'loading' : ''" @click="next" size="large" type="primary">{{ nextButtonText
					}}</a-button>
			</template>
		</div>
	</a-modal>
</template>

<script>
import { setProp, validateEmail } from 'bh-mod'
import SigningParty from '@/components/common/SigningParty'
import SignRoutePreview from '@/components/common/SignRoutePreview'
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		worksheet: {
			type: Object,
			default: () => ({})
		},
		type: {
			type: String,
			default: 'regular'
		}
	},
	components: { SigningParty, SignRoutePreview, ImageBoxSelector },
	data() {
		return {
			showError: false,
			esign: false,
			showRoute: false,
			loadModal: false,
			createStep: 0,
			chosenPurchaser: {},
			requiresRepReview: false,
			envelopeFileKey: null,
			newDoc: {
				name: '',
				template: '',
				sendReservationAmount: true,
				reservationAmount: null,
				currency: 'USD',
				isManual: false,
				questions: {
					customFields: {},
				},
				customFields: {},
			},
			signing: {
				purchaserSide: [],
				vendorSide: [],
				other: []
			},
			customFields: [],
		}
	},
	watch: {
		createStep(v) {
			if (v === 0) this.showRoute = false
		},

		'visible'(val) {
			if (val) {
				this.newDoc = {
					name: '',
					template: '',
					sendReservationAmount: true,
					reservationAmount: this.defaultReservationAmount,
					currency: this.defaultReservationCurrency,
					isManual: false,
					questions: {
						customFields: {},
					},
					customFields: {},
				}
				if (this.integrated.active) {
					this.newDoc.isManual = false
				} else {
					this.newDoc.isManual = true
				}
			}
			if (this.$refs.newDoc) {
				this.$refs.newDoc.resetFields()
			}
			this.envelopeData = null
			this.pagesArray = []
			this.esign = false
			this.createStep = 0
			this.showRoute = false
		},

		'newDoc.template'(val) {
			this.newDoc.questions = {
				customFields: {},
			}
			this.newDoc.name = ''
			this.newDoc.customFields = {}

			this.signing = {
				purchaserSide: [],
				vendorSide: [],
				other: []
			}

			if (!val) return
			if (!this.currentContract) return

			this.loadModal = true
			setTimeout(() => {
				this.loadModal = false
			}, 2000);

			let signingObj = {
				solicitor: false,
				agent: false,
				signingParty: false,
				purchasers: false,
				purchaserSide: true,
				action: 'cc',
				actionDisabled: false,
				disabled: false,
				other: true,
				predefined: true,
				id: Date.now(),
				name: '',
				role: '',
				supportingText: '',
				email: '',
				note: '',
				choice: 'other',
			}

			this.newDoc.name = this.currentContract.name || 'Unnamed Document'

			let pages = this.currentContract.pages

			let purchaserSide = []
			let vendorSide = {}
			let maxPurchasers = 0
			let maxSigningParties = 0
			let cF = this.currentContract.customFields || {}
			let customFields = {}

			let allPurchasers = []
			let signingParties = []
			let allSigningParties = []
			let signingReps = {
				solicitor: false,
				agent: false,
			}
			let signingList = {
				purchasers: {},
				reps: {},
				signingParty: {},
			}
			this.latestOrder = 0
			let defaultParticipants = this.participants
			let worksheetPurchasers = this.purchasers || []

			pages.forEach((page, pageI) => {
				let { fields = [] } = page
				fields.forEach(spot => {
					let { field, text } = spot
					let fV = field.value
					let fT = field.type
					let canSign = false
					if (fT === 'initial' || fT === 'sign') {
						canSign = true
					}

					if (field.custom && cF[field.value] && !customFields[field.value]) {
						customFields[field.value] = cF[field.value]
						let fieldKey = field.value.replace('other.customFields.', '')
						if (field.type == 'multiple' && cF[field.value].configure.defaultValue != '') {
							setProp(this.newDoc.customFields, [fieldKey], cF[field.value].configure.options[parseInt(cF[field.value].configure.defaultValue)]?.value)
						} else {
							setProp(this.newDoc.customFields, [fieldKey], cF[field.value].configure.defaultValue)
						}
					}

					if (fV.includes('purchasers[]')) {
						if (text.purchasers > maxPurchasers) maxPurchasers = text.purchasers
						if (!allPurchasers.includes(text.purchasers)) allPurchasers.push(text.purchasers)
						if (fT === 'initial' || fT === 'sign') {
							if (!signingParties.includes(text.purchasers)) signingParties.push(text.purchasers)
							if (worksheetPurchasers[text.purchasers - 1] && worksheetPurchasers[text.purchasers - 1].email) {
								if (!signingList.purchasers[text.purchasers]) {
									signingList.purchasers[text.purchasers] = {
										...signingObj,
										canSign,
										actionDisabled: true,
										disabled: true,
										order: 1,
										id: `purchase${text.purchasers},`,
										action: canSign ? 'sign' : 'cc',
										name: '',
										role: `purchase #${text.purchasers}`,
										supportingText: `purchase #${text.purchasers}`,
										email: '',
										purchasers: text.purchasers,
										signLocations: {}
									}
								}

								signingList.purchasers[text.purchasers].signLocations[spot.id] = {
									...spot,
									name: worksheetPurchasers[text.purchasers - 1].fullName,
									email: worksheetPurchasers[text.purchasers - 1].email,
									page: pageI,
									file: 0,
								}
							}
						}
					}

					if (fV.includes('signingParty[]')) {
						if (text.signingParty > maxSigningParties) maxSigningParties = text.signingParty
						if (!allSigningParties.includes(text.signingParty)) allSigningParties.push(text.signingParty)

						if (fT === 'initial' || fT === 'sign') {
							if (!signingParties.includes(text.signingParty)) signingParties.push(text.signingParty)

							if (!signingList.signingParty[text.signingParty]) {
								signingList.signingParty[text.signingParty] = {
									...signingObj,
									canSign,
									actionDisabled: true,
									disabled: false,
									order: 1,
									signingParty: text.signingParty,
									id: 'signingParty' + text.signingParty,
									action: canSign ? 'sign' : 'cc',
									name: '',
									role: text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
									supportingText: text.signingParty === 1 ? `Main Signing Party` : `Additional Signing Party #${text.signingParty - 1}`,
									email: '',
									signLocations: {}
								}
							}

							signingList.signingParty[text.signingParty].signLocations[spot.id] = {
								...spot,
								page: pageI,
								file: 0,
							}
						}
					}

					if (fT === 'initial' || fT === 'sign') {
						if (field.value.includes('reps.agent')) {
							signingReps.agent = true
							if (this.agent) {
								if (!signingList.reps.agent) {

									signingList.reps.agent = {
										...signingObj,
										canSign,
										actionDisabled: false,
										disabled: true,
										order: 1,
										id: 'agent',
										action: canSign ? 'sign' : 'cc',
										name: '',
										role: `purchase's Agent`,
										supportingText: `purchase's Agent`,
										email: '',
										signLocations: {}
									}
								}
								signingList.reps.agent.signLocations[spot.id] = {
									...spot,
									name: this.agent.fullName,
									email: this.agent.email,
									page: pageI,
									file: 0,
								}
							}
						}


						if (field.value.includes('reps.solicitor')) {
							signingReps.solicitor = true
							if (this.solicitor) {
								if (!signingList.reps.solicitor) {
									signingList.reps.solicitor = {
										...signingObj,
										canSign,
										actionDisabled: false,
										disabled: true,
										order: 1,
										id: 'solicitor',
										action: canSign ? 'sign' : 'cc',
										name: '',
										role: `purchase's Lawyer`,
										supportingText: `purchase's Lawyer`,
										email: '',
										signLocations: {},
									}
								}

								signingList.reps.solicitor.signLocations[spot.id] = {
									...spot,
									name: this.solicitor.fullName,
									email: this.solicitor.email,
									page: pageI,
									file: 0,
								}
							}
						}
					}
				})
			})

			purchaserSide = worksheetPurchasers.map((purchaser, purchaserI) => {
				this.latestOrder = 1
				if (signingList.purchasers[purchaserI + 1]) {
					signingList.purchasers[purchaserI + 1] = {
						...signingList.purchasers[purchaserI + 1],
						name: purchaser.fullName,
						id: `purchase${purchaserI + 1}_sign`,
						supportingText: `purchase #${purchaserI + 1}`,
						role: `purchase #${purchaserI + 1}`,
						order: this.latestOrder,
						email: purchaser.email,
						actionDisabled: signingList.purchasers[purchaserI + 1].actionDisabled,
					}
					return signingList.purchasers[purchaserI + 1]
				}

				return {
					...signingObj,
					canSign: false,
					actionDisabled: false,
					disabled: true,
					order: this.latestOrder,
					id: purchaser.id,
					action: this.doNotSendCopyTo.purchaser ? 'none' : 'cc',
					name: purchaser.fullName,
					role: `purchase #${purchaserI + 1}`,
					supportingText: `purchase #${purchaserI + 1}`,
					email: purchaser.email,
					purchasers: purchaserI + 1,
				}
			})

			if (this.solicitor) {
				let person = this.solicitor
				let canSign = Boolean(signingList.reps.solicitor)
				let solicitor = {
					...signingList.reps.solicitor,
					other: false,
					canBeIgnored: true,
					id: 'solicitor',
					actionDisabled: false,
					disabled: true,
					order: this.latestOrder += 1,
					canSign,
					action: canSign ? 'sign' : this.doNotSendCopyTo.lawyer ? 'none' : 'cc',
					name: person.firstName + ' ' + person.lastName,
                    role: `purchase's Lawyer`,
					supportingText: `purchase's Lawyer`,
					email: person.email,
				}
				purchaserSide.push(solicitor)
			}

			if (this.agent) {
				let person = this.agent
				let canSign = Boolean(signingList.reps.agent)
				let agent = {
					...signingList.reps.agent,
					actionDisabled: false,
					disabled: true,
					canBeIgnored: true,
					order: this.latestOrder += 1,
					id: 'agent',
					canSign,
					action: canSign ? 'sign' : this.doNotSendCopyTo.agent ? 'none' : 'cc',
					name: person.firstName + ' ' + person.lastName,
					role: `purchase's Agent`,
					supportingText: `purchase's Agent`,
					email: person.email,
				}
				purchaserSide.push(agent)
			}

			allSigningParties.forEach((sP, sPi) => {
				let canSign = false
				let obj = signingObj
				if (signingList.signingParty[sP]) {
					obj = signingList.signingParty[sP]
					canSign = true
				}

				let party = {
					...obj,
					roleDisabled: true,
					canSign,
					disabled: false,
					vendor: true,
					order: this.latestOrder += 1,
					id: Date.now() + sPi,
					action: canSign ? 'sign' : this.doNotSendCopyTo.seller ? 'none' : 'cc',
					name: '',
					email: '',
					supportingText: sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP - 1}`,
					role: sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP - 1}`,
					choice: 'other',
					actionDisabled: false,
				}

				if (defaultParticipants && defaultParticipants[sPi]) {
					party = {
						...party,
						choice: defaultParticipants[sPi].email,
						name: defaultParticipants[sPi].name,
						role: defaultParticipants[sPi].role || (sP === 1 ? 'Main Signing Party' : `Additional Signing Party #${sP - 1}`),
						email: defaultParticipants[sPi].email,
						company: defaultParticipants[sPi].company,
						id: defaultParticipants[sPi].id || party.id
					}
				}
				vendorSide[sP] = party
			})

			this.signing.vendorSide = Object.values(vendorSide)
			this.signing.purchaserSide = purchaserSide
			this.customFields = Object.values(customFields)
		}
	},
	computed: {
		instance() {
			return this.$store.state.instance
		},
		nextButtonText() {
			if (this.createStep === 0) return this.newDoc.isManual ? 'REVIEW & CREATE' : 'NEXT'
			return 'REVIEW & SEND'
		},
		integrated() {
			return this.$store.state.appData.vendors.docusign
		},
		modalTitle() {
			return this.type === 'release' ? 'Create Notice of Mutual Release' : 'Create Reservation Agreement'
		},
		pChoices() {
			let result = this.participants
			if (this.user) result = [...result, { ...this.user, name: `${this.user.firstName} ${this.user.lastName}`, role: 'Worksheet Approver' }]
			return result
		},
		signError() {
			let result = []
			this.signing.vendorSide.forEach(p => {
				result.push(Boolean(p.canSign && validateEmail(p.email)))
			})
			return result.includes(false)
		},
		signRoutePreview() {
			let route = {}
			if (this.requiresRepReview) {
				route = {
					0: {
						order: 0,
						signers: [{
							...this.reviewer
						}]
					}
				}
			}

			this.signing.purchaserSide.forEach(p => {
				if (p.hidden) return
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push(p)
			})

			this.signing.vendorSide.forEach(p => {
				if (p.hidden) return
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push(p)
			})

			this.signing.other.forEach(p => {
				if (p.hidden) return
				if (!p.name.trim() || !p.email.trim()) return
				if (!route[p.order]) route[p.order] = { order: p.order, signers: [] }
				route[p.order].signers.push(p)
			})

			let routes = Object.values(route).sort((a, b) => a.order > b.order ? 1 : -1)

			return routes
		},
		participants() {
			return this.$store.state.appData.participants;
		},
		purchasers() {
			const purchasers = this.worksheet && this.worksheet.data && this.worksheet.data.purchasers || [];

			return purchasers.map((applicant) => {
				let purchaser = {
					id: applicant.id || Date.now(),
					firstName: applicant.firstName,
					middleName: applicant.middleName,
					lastName: applicant.lastName,
					email: applicant.email,
					phone: applicant.phone && applicant.phone.toString(),
					company: applicant.company,
					business: applicant.business && applicant.business.toString() || '',
					cell: applicant.cell && applicant.cell.toString() || '',
					dob: applicant.dob || '',
					issuing: applicant.issuing || '',
					expiry: applicant.expiry || '',
					idType: applicant.idType || 'pp',
					idValue: applicant.idValue || '',
					passportOtherValue: applicant.passportOtherValue || '',
					passportOtherIssuing: applicant.passportOtherIssuing || '',
					passportOtherIssuingCountry: applicant.passportOtherIssuingCountry || '',
					passportOtherExpiry: applicant.passportOtherExpiry || '',
					prValue: applicant.prValue || '',
					prIssuing: applicant.prIssuing || '',
					prIssuingCountry: applicant.prIssuingCountry || '',
					prExpiry: applicant.prExpiry || '',
					wpValue: applicant.wpValue || '',
					wpIssuing: applicant.wpIssuing || '',
					wpIssuingCountry: applicant.wpIssuingCountry || '',
					wpExpiry: applicant.wpExpiry || '',
					bcValue: applicant.bcValue || '',
					bcIssuing: applicant.bcIssuing || '',
					bcIssuingCountry: applicant.bcIssuingCountry || '',
					otherName: applicant.otherName || '',
					otherValue: applicant.otherValue || '',
					otherIssuing: applicant.otherIssuing || '',
					otherIssuingCountry: applicant.otherIssuingCountry || '',
					otherExpiry: applicant.otherExpiry || '',
                    birthPlace: applicant.birthPlace || '',
                    registrationDate: applicant.registrationDate || '',
                    issueDate: applicant.issueDate || '',
					address1: applicant.address || applicant.address1 || '',
					address2: applicant.address2 || '',
					postal: applicant.postal || '',
					country: applicant.country || '',
					region: applicant.region || '',
					city: applicant.city || '',
					jobTitle: applicant.jobTitle || '',
					ssnsin: applicant.ssnsin || '',
					fullName: `${applicant.firstName} ${applicant.lastName}`,
					address: `${applicant.address1}${applicant.address2 ? ', ' + applicant.address2 : ''}`,
					cityRegionPostalZip: `${applicant.city}, ${applicant.region}, ${applicant.postal}`,
					fullAddress: `${applicant.address || applicant.address1}, ${applicant.address2}, ${applicant.city}, ${applicant.region}, ${applicant.postal}`.replaceAll(', , ', ', ').trim(),
					licenseIdType: applicant.licenseIdType || '',
					passportOtherIdType: applicant.passportOtherIdType || '',
					prIdType: applicant.prIdType || '',
					wpIdType: applicant.wpIdType || '',
					bcIdType: applicant.bcIdType || ''
				}

				return purchaser;
			})
		},
		agent() {
			const agent = this.worksheet.submittedBy;

			if (agent && agent.email) {
				return {
					...agent,
					fullName: agent.firstName + " " + agent.lastName,
					firstName: agent.firstName || '',
					lastName: agent.lastName || '',
					email: agent.email || '',
					phone: agent.phone || '',
					fax: agent.fax || '',
					company: agent.company || '',
					jobTitle: agent.jobTitle || '',
					address1: agent.address1 || '',
					address2: agent.address2 || '',
					country: agent.country || '',
					region: agent.region || '',
					city: agent.city || '',
					postal: agent.postal || '',
					id: 'agent',
					address: `${agent.company ? agent.company + ', ' : ''}${agent.address1}`,
					cityRegionPostalZip: `${agent.city}, ${agent.region}, ${agent.postal}`,
					fullAddress: `${agent.address1}, ${agent.address2}, ${agent.city}, ${agent.region}, ${agent.postal}`.replaceAll(', , ', ', ').trim()
				}
			}
			return null
		},
		solicitor() {
			const solicitor = this.worksheet.data.solicitor;
			if (solicitor && solicitor.email) {
				return {
					...solicitor,
					fullName: solicitor.firstName + " " + solicitor.lastName,
					firstName: solicitor.firstName || '',
					lastName: solicitor.lastName || '',
					email: solicitor.email || '',
					fax: solicitor.fax || '',
					phone: solicitor.phone || '',
					company: solicitor.company || '',
					jobTitle: solicitor.jobTitle || '',
					address1: solicitor.address1 || '',
					address2: solicitor.address2 || '',
					country: solicitor.country || '',
					region: solicitor.region || '',
					city: solicitor.city || '',
					postal: solicitor.postal || '',
					id: 'solicitor',
					address: `${solicitor.company ? solicitor.company + ', ' : ''}${solicitor.address1}`,
					cityRegionPostalZip: `${solicitor.city}, ${solicitor.region}, ${solicitor.postal}`,
					fullAddress: `${solicitor.address1}, ${solicitor.address2}, ${solicitor.city}, ${solicitor.region}, ${solicitor.postal}`.replaceAll(', , ', ', ').trim()
				}
			}
			return null
		},
		currentContract() {
			if (this.newDoc.template) {
				return this.contractTemplates.find(c => c.id === this.newDoc.template)
			}
			return false;
		},
        doNotSendCopyTo() {
            return this.currentContract?.options?.doNotSendCopyTo || { purchaser: false, lawyer: false, agent: false, seller: false }
        },
		contractTemplates() {
			return this.$store.state.appData.contractTemplates || []
		},
		reservationTemplates() {
			return this.contractTemplates.filter(c => c.type === 'reservation')
		},
		user() {
			return this.$store.state.user.user
		},
		reviewer: {
			get() {
				const user = {
					id: this.user.id,
					order: 0,
					action: 'approve',
					name: this.user.firstName + ' ' + this.user.lastName,
					role: 'Reviewer',
					email: this.user.email
				}
				return user
			},
			set(val) {

			}
		},
		defaultReservationAmount() {
			return this.$store.state.appData.reservationAmount || 0
		},
		defaultReservationCurrency() {
			return this.$store.state.appData.reservationCurrency || 'USD'
		},
		inclusions() {
			let incs = this.$store.state.appData.inclusions
			return incs.map(inc => ({
				label: inc.title,
				value: inc.id,
				body: inc.body
			}))
		},
	},
	methods: {
		req: (msg, required = true) => ({ required: required, message: msg }),

		addRecipient() {
			this.signing.other.push({
				canBeRemoved: true,
				action: 'cc',
				canSign: false,
				vendor: true,
				id: Date.now(),
				name: '',
				order: this.latestOrder += 1,
				role: '',
				supportingText: '',
				email: '',
				note: '',
				choice: 'other',
			})
		},

		close() {
			this.$emit('close')
		},

		next() {
			if (this.newDoc.isManual) {
				return this.generatePreview()
			}
			this.showError = false
			if(!this.integrated.active && !this.newDoc.isManual) return this.$message.error('Please setup Docusign integration first.')
			if (!this.newDoc.template) return this.$message.error('Please select a template')
			if (this.newDoc.sendReservationAmount && !this.newDoc.reservationAmount && this.type !== 'release') return this.$message.error('Please enter reservation amount.')
			if (this.newDoc.sendReservationAmount && !this.instance.stripeAccountActivated) return this.$message.error('Please setup payment integration first.')
			if (this.newDoc.reservationAmount && this.newDoc.reservationAmount < 100) return this.$message.error('Reservation amount cannot be less than $100. Minimum $100 is required.')
			if (this.createStep === 0) return this.createStep = 1
			if (this.signError) return this.showError = true
			return this.generatePreview()
		},

		async generatePreview() {
			let template = this.currentContract
			let worksheet = JSON.parse(JSON.stringify(this.worksheet))
			this.$store.commit('LOAD', true)

			if (worksheet.unit && worksheet.unit.packages) {
				worksheet.unit.package = worksheet.unit.packages[0]
			}

			if (worksheet.package) {
				worksheet.unit.package = worksheet.package
			}

			let product = {
				unit: worksheet.unit
			}

			if (worksheet.lot) {
				product.lot = worksheet.lot
			}

			let payload = {
				purchasers: this.purchasers || [],
				signingParty: [...this.signing.vendorSide],
				product: product,
				other: {
					customFields: {}
				},
				reps: {
					agent: {
						...this.agent
					},
					solicitor: {
						...this.solicitor
					}
				}
			}

			if (this.newDoc.sendReservationAmount && this.type !== 'release') {
				payload.reservationAmount = this.newDoc.reservationAmount
				payload.currency = this.newDoc.currency
			}

			Object.entries(this.newDoc.customFields).forEach(([key, value]) => {
				key = key.replace('other.customFields.', '')
				payload.other.customFields[key] = value
			})

			try {
				let { data } = await this.$api.post(`/contract-templates/:instance/${template.id}/preview`, payload)
				let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${data}`);

				this.envelopeFileKey = data
				this.$store.commit('REVIEW_SEND', { preview: url, callback: this.type !== 'release' ? this.createEnvelope : this.addMutualReleaseForReservation, isManual: this.newDoc.isManual, buttonText: 'SEND CONTRACT' })
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error occurred while previewing contract. Please try again!'))
				}
			}
			this.$store.commit('LOAD', false)
		},

		async createEnvelope() {
			let nameLookup = {
				release: 'Notice of Mutual Release',
			}

			let payload = {
				name: nameLookup[this.type] ? nameLookup[this.type] : this.newDoc.name,
				isManual: this.newDoc.isManual,
				requiresRepReview: this.requiresRepReview,
				template: this.newDoc.template,
				type: this.type || 'regular',
				file: {
					template: this.newDoc.template,
					key: this.envelopeFileKey
				},
			}

			if (this.newDoc.sendReservationAmount && this.type !== 'release') {
				payload.reservationAmount = this.newDoc.reservationAmount
				payload.currency = this.newDoc.currency
			}

			if (!this.newDoc.isManual) {
				payload.signers = this.signRoutePreview
			}

			this.$store.commit('LOAD', true)

			this.$api.post(`/worksheets/${this.instance.id}/${this.worksheet.id}/send-reservation-payment-link`, payload)
				.then(({ data }) => {
					this.$store.dispatch('FETCH_WORKSHEET', this.worksheet.id)
					this.$store.commit('CLOSE_PREVIEW')
					this.$store.commit('LOAD', false)
					this.$message.success('Reservation Agreement sent for Signing!')
					this.close()
				})
				.catch(err => {
					this.$store.commit('LOAD', false)
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
					}
				})

		},

		async addMutualReleaseForReservation() {
			this.$store.commit('LOAD', true)
			let payload = {
				isManual: this.newDoc.isManual,
				requiresRepReview: this.requiresRepReview,
				type: 'release',
				file: {
					template: this.newDoc.template,
					key: this.envelopeFileKey
				},
			}

			if (!this.newDoc.isManual) {
                const signers = [...this.signRoutePreview];

                if (this.requiresRepReview) {
                    signers.splice(0, 1);
                }
				payload.signers = signers
			}

			try {
				await this.$api.post(`/worksheets/:instance/${this.worksheet.id}/add-mutual-release-for-worksheet`, payload);
				this.$store.dispatch('FETCH_WORKSHEET', this.worksheet.id);
				this.$store.commit('CLOSE_PREVIEW');
				this.close()
				this.$message.success('Mutual release notice send successfully!');
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, "Error occurred while creating notice of mutual release for the worksheet reservation. Please try again!"))
				}
			}
			this.$store.commit('LOAD', false)
		},
	}
}
</script>

<style>
.signer-action-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	background: white;
	pointer-events: none;
	padding: 5px;
	border-radius: 50%;
	box-shadow: 0 2px 10px rgba(100, 100, 100, 0.2);
	font-size: 10px;
}
</style>
